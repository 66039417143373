<template>
  <div>
    <Frame title="Importar nóminas" 
      titleAction="Cargar archivos"
      :loading="isLoading"
    >
      <Card
        v-if="batchDetail && !batchDetail.notAllowed && isCompleted"
        class="payroll_notify payroll_notify__procesed"
        description="No es posible modificar los archivos importados ya que la nómina se encuentra en proceso o ha sido cerrada."
      />
      <layout v-if="batchDetail && batchDetail.notAllowed">
        <Column size="5"></Column>
        <Column size="2">
          <ActionCard image="/assets/unauthorized.svg" text="Acceso denegado" />
        </Column>
      </layout>

      <Layout v-if="batchDetail && !batchDetail.notAllowed">
        <Column style="margin-bottom: 24px" v-if="entity">
          <Layout>
            <Column size="4">
              <span style="font-weight: bold">Descripción: </span> {{ entity.LoadName }}
            </Column>
            <Column size="4">
              <span style="font-weight: bold">Fecha: </span> {{ entity.CreationDate }}
            </Column>
            <Column size="4">
              <span style="font-weight: bold">Estatus: </span> <span v-html="formatCode(entity)" />
            </Column>
          </Layout>
        </Column>
        <Column size="6">
          <LoadDataItem
            title="Catálogo de colaboradores"
            batchDetailType="EMP"
            :batchDetail="batchDetail"
            @uploading="uploading"
            :disabled="isCompleted"
          />
        </Column>
        <Column size="6">
          <LoadDataItem
            title="Catálogo periodos de nómina"
            batchDetailType="PER"
            :batchDetail="batchDetail"
            @uploading="uploading"
            :disabled="isCompleted"
          />
        </Column>
        <Column size="4">
          <LoadDataItem
            title="Colaboradores datos de nómina"
            batchDetailType="PAYEMP"
            :batchDetail="batchDetail"
            class="is-small-upload"
            @uploading="uploading"
            :disabled="isCompleted"
          />
        </Column>
        <Column size="4">
          <LoadDataItem
            title="Conceptos aplicados en nómina"
            batchDetailType="PAYPERDEDU"
            :batchDetail="batchDetail"
            class="is-small-upload"
            @uploading="uploading"
            :disabled="isCompleted"
          />
        </Column>
        <Column size="4">
          <LoadDataItem
            title="SAE conforme tabla / saldo ISR a favor"
            batchDetailType="PAYSAEISR"
            :batchDetail="batchDetail"
            class="is-small-upload"
            @uploading="uploading"
            :disabled="isCompleted"
          />
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button color="terciary-outlined" @click="$router.push({ name: 'importData' })">
            Regresar
          </Button>
          <Button
            v-if="batchDetail && !batchDetail.notAllowed && $can('AddLoadData', 'STM')"
            :disabled="!canApplytoDB || isCompleted"
            @click="applyToDB"
            color="secondary"
            >Aplicar a la base datos
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
import LoadDataItem from './LoadDataItem.vue';
import ApiClient from '../../../utils/ApiClient';

export default {
  name: 'LoadData',
  components: {
    LoadDataItem,
  },
  data() {
    return {
      entity: null,
      batchDetail: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getLoadData();
    this.getLoadDetail();
    this.$root.$on('reloadData', () => {
      this.getLoadDetail();
    });
  },
  methods: {
    getLoadData() {
      const { id } = this.$route.params;
      const apiClient = new ApiClient('file/loads/STM', 'common');
      apiClient.Get().then((data) => {
        this.loading = false;
        this.entity = data.find((x) => x.LoadProcessID === id);
      });
    },
    getLoadDetail() {
      const { id } = this.$route.params;
      this.getAll(`file/loads/detail`, `${id}?query=a`, 'common').then(
        (data) => {
          if (data) {
            this.batchDetail = data;
          } else {
            this.batchDetail = {
              isReadyToApply: false,
              loadProcessID: id,
              batches: [],
            };
          }
        },
        () => {
          this.batchDetail = {
            notAllowed: true,
          };
        }
      );
    },
    uploading(status) {
      this.isLoading = status;
    },
    applyToDB() {
      this.isLoading = true;
      this.$post(`file/loads/apply/${this.batchDetail.loadProcessID}`, {}, 'common')
        .then((data) => {
          this.isLoading = false;
          if (data) {
            this.setNotification('Se ha aplicado correctamente la carga.', 'success');
            this.$router.push({ name: 'importData' });
          }
        })
        .catch(({ response }) => {
          this.isLoading = false;
          this.$root.$emit('reloadData');
          this.setNotification(response.data, 'warning');
        });
    },
    formatCode(item) {
      const codes = [
        {
          code: 'COMPLETED',
          text: 'Completado',
          color: 'is-success',
        },
        {
          code: 'FAILED',
          text: 'Fallido',
          color: 'is-quinary',
        },
        {
          code: 'INPROC',
          text: 'En proceso',
          color: 'is-terciary-outlined',
        },
        {
          code: 'NEW',
          text: 'Nuevo',
          color: 'is-terciary',
        },
      ];

      const code = codes.find((x) => x.code === item.StatusCode);
      return `<span class="pw-tag ${code.color}">${code.text}</span>`;
    },
  },
  computed: {
    canApplytoDB() {
      return (
        ((this.batchDetail || {}).batches || []).filter((e) => e.batchFileStatusCode === 'ERROR')
          .length < 1 &&
        ((this.batchDetail || {}).batches || []).length > 0 &&
        !this.isLoading
      );
    },
    isCompleted() {
      return (this.batchDetail || {}).loadProcessStatus === 'COMPLETED';
    },
  },
};
</script>

<style lang="scss">
.payroll_notify {
  .card__title {
    display: none;
  }

  padding: 7px 12px;

  &__not_allowed {
    background-color: #f9c0c0;

    &::before {
      content: '\F028';
      font-family: 'Material Design Icons';
      position: relative;
      color: #f25555;
      font-size: 20px;
    }
  }

  &__procesed {
    background-color: #fff4da;

    &::before {
      content: '\F026';
      font-family: 'Material Design Icons';
      position: relative;
      color: #ffc848;
      font-size: 20px;
    }
  }
}
</style>
